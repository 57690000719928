import React, { useState } from 'react';
import "./Convert.css";
import { FaFileUpload } from "react-icons/fa";
import { PDFDocument } from 'pdf-lib';

const Convert = () => {
  const [files, setFiles] = useState([]);

  // Handle file upload
  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles(selectedFiles);
  };

  // Convert images to PDF
  const handleConvert = async () => {
    if (files.length === 0) {
      alert("Please upload at least one JPG image.");
      return;
    }

    try {
      const pdfDoc = await PDFDocument.create();

      for (const file of files) {
        const imageBytes = await file.arrayBuffer();
        const image = await pdfDoc.embedJpg(imageBytes);
        const { width, height } = image.scale(1);
        const page = pdfDoc.addPage([width, height]);
        page.drawImage(image, {
          x: 0,
          y: 0,
          width,
          height,
        });
      }

      const pdfBytes = await pdfDoc.save();
      const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
      const url = URL.createObjectURL(pdfBlob);

      // Create a link to download the PDF
      const link = document.createElement('a');
      link.href = url;
      link.download = 'converted_file.pdf';

      // Append to body, click to download, and then remove it
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up

      // Release the object URL
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error converting images to PDF:", error);
      alert("An error occurred while converting the images to PDF. Please try again.");
    }
  };

  return (
    <div className="carde-container">
      <div className="carde-body">
        <h5 className="carde-title"><b>Convert JPG to PDF</b></h5>
        <p className="carde-text">Convert JPG images to PDF in seconds. Easily adjust orientation and margins.</p>
        <div className='load'>
          <input
            className='load'
            type="file"
            id="uploadBtn"
            onChange={handleFileChange}
            accept=".jpg,.jpeg"
            multiple
          />
          <label htmlFor="uploadBtn"><FaFileUpload /> Upload Files</label>
        </div>
        <button onClick={handleConvert}>Convert to PDF</button>
      </div>
    </div>
  );
};

export default Convert;
