import React from 'react'
import Navbar from './component/Navbar/Navbar';
import Home from './component/home/Home';
import Footer from './component/Footer/Footer';
import Compress from './component/Compress/Compress';
import{ BrowserRouter as Router, Routes,Route} from 'react-router-dom';
import Resize from './component/Resize/Resize';
import Merge from './component/Merge/Merge';
import Convert from './component/Convert/Convert';
import Signup from './component/Signup/Signup';
import Signin from './component/Signin/Signin';


const App = () => {
  return (
    <div>
     <Router>
     <Navbar/>
      <Routes>
        <Route exact path='/'element={<Home/>}/>
        <Route  path='/Compress'element={<Compress/>}/>
        <Route  path='/resize'element={<Resize/>}/>
        <Route  path='/Merge'element={<Merge/>}/>
        <Route  path='/convert'element={<Convert/>}/>
        <Route  path='/signup'element={<Signup/>}/>
        <Route path='/signin' element={<Signin/>}/>

       
      </Routes>
     </Router>

      
     
      
     <Footer/>
    </div>
  );
};

export default App;
