import React, { useState } from 'react';
import "./Resize.css"; // Ensure you have styles in this file
import { FaFileUpload } from "react-icons/fa";
import { PDFDocument } from 'pdf-lib';

const Resize = () => {
  const [file, setFile] = useState(null);
  const [scaleFactor, setScaleFactor] = useState(1);

  // Handle file upload
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type === 'application/pdf') {
      setFile(selectedFile);
    } else {
      alert("Please upload a valid PDF file.");
    }
  };

  // Resize the PDF
  const handleResize = async () => {
    if (!file) {
      alert("Please upload a PDF file.");
      return;
    }
  
    // Ensure scale factor is valid
    if (isNaN(scaleFactor) || scaleFactor <= 0) {
      alert("Please enter a valid scale factor greater than 0.");
      return;
    }
  
    try {
      // Read the PDF file as bytes
      const existingPdfBytes = await file.arrayBuffer();
      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      const newPdfDoc = await PDFDocument.create();
  
      // Get all pages from the original PDF
      const pages = pdfDoc.getPages();
  
      // Loop through each page to resize
      for (const page of pages) {
        const { width, height } = page.getSize();
        const newPage = newPdfDoc.addPage([width * scaleFactor, height * scaleFactor]);
  
        // Get the index of the current page
        const pageIndex = pdfDoc.getPageIndex(page);
        
        // Copy the original page's contents on the new page
        const [copiedPage] = await newPdfDoc.copyPages(pdfDoc, [pageIndex]);
        newPage.drawPage(copiedPage);
      }
  
      // Save the new resized PDF document
      const pdfBytes = await newPdfDoc.save();
      const resizedPDF = new Blob([pdfBytes], { type: 'application/pdf' });
      const url = URL.createObjectURL(resizedPDF);
  
      // Create a link to download the resized PDF
      const link = document.createElement('a');
      link.href = url;
      link.download = 'resized_file.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up
  
      // Release the object URL
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error resizing PDF:", error);
      alert("An error occurred while resizing the PDF. Please try again.");
    }
  };

  return (
    <div className="carde-container">
      <div className="carde-body">
        <h5 className="carde-title"><b>Resize PDF Files</b></h5>
        <p className="carde-text">Resize your PDF files by scaling the pages.</p>
        <div className='load'>
          <input
            className='load'
            type="file"
            id="uploadBtn"
            onChange={handleFileChange}
            accept=".pdf"
          />
          <label htmlFor="uploadBtn"><FaFileUpload /> Upload File</label>
        </div>
        <div className="scale-input">
          <input
            type="number"
            value={scaleFactor}
            onChange={(e) => setScaleFactor(parseFloat(e.target.value))}
            step="0.1"
            min="0.1"
            max="10"
            placeholder="Enter scale factor"
          />
        </div>
        <button onClick={handleResize}>Resize PDF</button>
      </div>
    </div>
  );
};

export default Resize;
