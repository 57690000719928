import React from 'react'

const HeadingComp = (first,second) => {
  return (
    <div> 
        <h2 className='signup'>signup</h2>
        </div>
  );
};

export default HeadingComp;