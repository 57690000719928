import React, { useState } from 'react';
import "./Merge.css";
import { FaFileUpload } from "react-icons/fa";
import { PDFDocument } from 'pdf-lib';

const Merge = () => {
  const [files, setFiles] = useState([]);

  // Handle file upload
  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles(selectedFiles);
  };

  // Merge the PDFs
  const handleMerge = async () => {
    if (files.length === 0) {
      alert("Please upload at least one PDF file.");
      return;
    }

    try {
      const mergedPdfDoc = await PDFDocument.create();

      // Loop through each file and merge
      for (const file of files) {
        const existingPdfBytes = await file.arrayBuffer();
        const pdfDoc = await PDFDocument.load(existingPdfBytes);
        const copiedPages = await mergedPdfDoc.copyPages(pdfDoc, pdfDoc.getPageIndices());
        
        copiedPages.forEach((page) => {
          mergedPdfDoc.addPage(page);
        });
      }

      // Save the merged PDF document
      const pdfBytes = await mergedPdfDoc.save();
      const mergedPDF = new Blob([pdfBytes], { type: 'application/pdf' });
      const url = URL.createObjectURL(mergedPDF);

      // Create a link to download the merged PDF
      const link = document.createElement('a');
      link.href = url;
      link.download = 'merged_file.pdf';

      // Append to body, click to download, and then remove it
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up

      // Release the object URL
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error merging PDFs:", error);
      alert("An error occurred while merging the PDFs. Please try again.");
    }
  };

  return (
    <div className="carde-container">
      <div className="carde-body">
        <h5 className="carde-title"><b>Merge PDF Files</b></h5>
        <p className="carde-text">Combine PDFs in the order you want the easiest PDF merger available.</p>
        <div className='load'>
          <input
            type="file"
            id="uploadBtn"
            onChange={handleFileChange}
            accept=".pdf"
            multiple
          />
          <label htmlFor="uploadBtn"><FaFileUpload /> Upload Files</label>
        </div>
        <button onClick={handleMerge}>Merge PDFs</button>
      </div>
    </div>
  );
};

export default Merge;
