import React from 'react'
import "./Signup.css";
import HeadingComp from './HeadingComp';
const Signup = () => {
  
  return (
    <div className="signup-container">
      <HeadingComp first="sign" second="up"/>
      <form  className="signup-form">
        <input
          type="text"
          name="name"
          placeholder="Name"
          
        />
        <input
          type="email"
          name="email"
          placeholder="Email"
         
        />
        <input
          type="password"
          name="password"
          placeholder="Password"
          
        />
        <button type="submit" className="signup-button">
          Signup
        </button>

        


      </form>
       
    </div>
  );
};

export default Signup;