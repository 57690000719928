import React from 'react';
import './Footer.css';
import { FaFacebookSquare } from "react-icons/fa";
import { FaSquareInstagram, FaLinkedin } from "react-icons/fa6";

export const Footer = () => {
  return (
    <footer>
      <div className="f-info">
        <div className="f-info-socials">
          <FaFacebookSquare className="social-icon" />
          <FaSquareInstagram className="social-icon" />
          <FaLinkedin className="social-icon" />
        </div>
        <div className="f-info-brand"> Gloomweb solutions &copy;2024</div>
        <div className="f-info-links">
          <a href="/privacy">Privacy </a>
         
          <a href="/terms">Terms</a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
