import React from 'react'
import "./Navbar.css";

import { Link } from 'react-router-dom';
const Navbar = () => {
  return (
    <>
    
    <nav className="navbar navbar-expand-lg bg-body-tertiary fixed-top ">
  <div className="container-fluid">
   <Link className="navbar-brand" to="#"><b> <img src="https://www.gloomweb.com/wp-content/uploads/2024/03/Header-Logo-1.png" alt="logo"/> </b></Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
         <Link className="nav-link active home-link" aria-current="page" to="/">Home</Link>
        </li>
       
        <li className="nav-item">
         <Link className="nav-link active home-link" aria-current="page" to="/compress">Compress PDF</Link>
        </li>
        <li className="nav-item">
         <Link className="nav-link active home-link" aria-current="page" to="/resize">Resize</Link>
        </li>
      
        <li className="nav-item dropdown home-link">
         <Link className="nav-link dropdown-toggle " to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false" >
            Tools
          </Link>
          <ul className="dropdown-menu">
           
            <li><Link className="dropdown-item home-link" to="/merge">Merge PDF</Link></li>
            <li><Link className="dropdown-item home-link" to="/convert">JPG to PDF</Link></li>
          </ul>
        </li>
        
      </ul>
      {/* <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
          
     
      <li className="nav-item mx-2">
         <Link className="nav-link active btn-nav home-link " aria-current="page" to="/signin">Log In</Link>
        </li>

      <li className="nav-item mx-2">
         <Link className="nav-link active btn-nav home-link " aria-current="page" to="/signup">Sign up</Link>
        </li>



        

      </ul> */}
    
    </div>
  </div>
</nav>
    </>
  );
};

export default Navbar;