import React from 'react';
import './Signin.css'
import { Link } from 'react-router-dom';


 const SignIn = () =>{

  return (
    <div className='signup-container'>
      <h2 className='In'> Login</h2>
      <form >
        <div className='input'>
          <label>Email:</label>
          <input
            type="email"
            placeholder="Enter Email"
            
           
          />
        </div>
        <div>
          <label>Password:</label>
          <input
            type="password"
              placeholder="Enter Password"
          
            
          />
        </div>
        <div className='btn-1'>
        <button   type="submit">Login</button>
       
        </div>
      </form>

      <Link className="signin " aria-current="page" to="/signup">Sign up</Link>
     </div>
  );
}

export default SignIn;
