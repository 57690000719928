import React, { useState } from 'react';
import "./Compress.css";
import { FaCompress } from 'react-icons/fa';

import imageCompression from 'browser-image-compression';
import { PDFDocument } from 'pdf-lib';

const Compress = () => {
  const [file, setFile] = useState(null);
  const [compressedFile, setCompressedFile] = useState(null);

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);

    if (selectedFile) {
      if (selectedFile.type.includes('image')) {
        await compressImage(selectedFile);
      } else if (selectedFile.type.includes('pdf')) {
        await compressPDF(selectedFile);
      } else {
        alert("Please upload a valid image (JPG/PNG) or PDF file.");
      }
    }
  };

  const compressImage = async (imageFile) => {
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1820,
      useWebWorker: true,
    };
    
    try {
      const compressed = await imageCompression(imageFile, options);
      setCompressedFile(compressed);
      console.log("Compressed image file:", compressed);
    } catch (error) {
      console.error("Error compressing image:", error);
    }
  };

  const compressPDF = async (pdfFile) => {
    const existingPdfBytes = await pdfFile.arrayBuffer();
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    
    // This is a simple placeholder for compression logic.
    const pdfBytes = await pdfDoc.save();
    const compressedPDF = new Blob([pdfBytes], { type: 'application/pdf' });
    setCompressedFile(compressedPDF);
    console.log("Compressed PDF file:", compressedPDF);
  };

  return (
    <div className="carde-container">
      <div className="carde-body">
        <h5 className="carde-title"><b>Compress PDF and Image Files</b></h5>
        <p className="carde-text">Compress JPG and PNG images while saving space and maintaining quality.</p>
        <div className='load'>
          <input
            className='load'
            type="file"
            id="uploadBtn"
            onChange={handleFileChange}
            accept=".jpg,.jpeg,.png,.pdf"
          />
          <label htmlFor="uploadBtn">< FaCompress  /> Upload File</label>
        </div>
        {compressedFile && (
          <div className="compressed-file">
            <h6>Compressed File:</h6>
            <a href={URL.createObjectURL(compressedFile)} download="compressed_file">Download</a>
          </div>
        )}
      </div>
    </div>
  );
};

export default Compress;
